<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑节日提醒"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="提醒名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入提醒名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="日期类型">
          <a-radio-group
            v-decorator="['date_type', {
              rules: [
                { required: true, message: '请选择日期类型' },
              ],
              initialValue: 0
            },
            ]"
          >
            <a-radio :value="0">阴历</a-radio>
            <a-radio :value="1">阳历</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="节日名称">
          <a-input
            v-decorator="['festival_name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入节日名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
            @change="createContent"
          />
        </a-form-item>
        <a-form-item label="节日日期" :required="true">
          <a-row :span="24">
            <a-col :span="9">
              <a-form-item>
                <a-input-number
                  :min="1"
                  :max="12"
                  :step="1"
                  :precision="0"
                  v-decorator="['month', {
                    rules: [
                      { required: true, message: '请输入月' },
                    ]
                  }]"
                />
                月
              </a-form-item>
            </a-col>
            <a-col :span="15">
              <a-form-item>
                <a-input-number
                  :min="1"
                  :max="31"
                  :step="1"
                  :precision="0"
                  v-decorator="['day', {
                    rules: [
                      { required: true, message: '请输入日' },
                    ]
                  }]"
                />
                日
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="发送时间">
          节日前
          <a-input-number
            style="width: 75%;"
            :min="1"
            :max="999"
            :step="1"
            :precision="0"
            @change="createContent"
            v-decorator="['send_date', {
              rules: [
                { required: true, message: '请输入发送时间' },
              ]
            }]"
          />  天
        </a-form-item>
        <a-form-item label="提醒内容">
          <h5 class="content">A表示输入的天数，B表示表示输入的节日名称</h5>
          <a-textarea
            :disabled="true"
            v-decorator="['content', {
              initialValue:'还有A天就是B了，不要忘了祭祖扫墓。',
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateFestivalRemind } from '@/api/festival_remind'

export default {
  name: 'EditFestivalRemind',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'festival_remind' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.data = Object.assign({}, {
        name: this.record.name,
        date_type: this.record.date_type,
        month: this.record.month,
        day: this.record.day,
        content: this.record.content,
        festival_name: this.record.festival_name,
        send_date: this.record.send_date
      })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
      })
    },
    createContent() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          content: '还有' + (this.form.getFieldValue('send_date') ? this.form.getFieldValue('send_date') : 'A') +
              '天就是' + this.form.getFieldValue('festival_name') +
              '了，不要忘了祭祖扫墓。'
        })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (values.date_type === 0) {
          if (values.day > 30) {
            this.$warning({
              title: '阴历日期最大为30',
              content: ''
            })
            return
          }
        }
        if (!err) {
          this.submitting = true
          updateFestivalRemind(this.record.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang= "less" scoped>
.content {
  color: #b1b0b0;
  line-height: 120%;
}
</style>
